import { Note } from "@types"
import Image from "next/image"

/**
 * This is the cover photo of the post that gets displayed above the title.
 * See PostImage.tsx for the AllPosts cover photo (which may be different!)
 */
export default function HeroImage({ note }: { note: Note }) {
  // Sometimes we populate the cover_img with an image from the post body,
  // so that we display it on the all-posts page,
  // but we never want this to show as the cover_img. In that case, isHero is false.
  if (!note.cover_img || !note.cover_img.isHero) return null

  return (
    <div className="sm:px-4 max-w-[876px] my-8">
      <Image
        src={note.cover_img.img.src}
        placeholder="blur"
        alt="Cover photo"
        className="object-cover object-center max-h-[422px] sm:rounded-2xl mx-auto shadow-2xl"
        sizes="(min-width: 1024px) 848px, 100vw"
        blurDataURL={note.cover_img.base64}
        height={note.cover_img.img.height}
        width={note.cover_img.img.width}
        priority
      />
    </div>
  )
}
