import { selectBlog } from "features/blogSlice"
import { selectCurrentNote } from "features/noteSlice"
import { makeToast } from "features/pageSlice"
import useNoteUrl from "hooks/useNoteUrl"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useAppDispatch } from "store"
import useUser from "./useUser"

export const useJumpLinks = (editorReady: boolean) => {
  const [done, setDone] = useState(false)
  const blog = useSelector(selectBlog)
  const note = useSelector(selectCurrentNote)
  const { fullNoteUrl } = useNoteUrl({ blog, note, forceSlug: false })
  const dispatch = useAppDispatch()
  const user = useUser()

  useEffect(() => {
    if (!editorReady || done) return

    // Check if user has wallet so we can set referrer
    let referrer = ""
    if ("wallet_address" in user && user.wallet_address)
      referrer = user.wallet_address

    // Add jump tags to headers
    Array.from(
      document.querySelectorAll(
        "div.tiptap h1, div.tiptap h2, div.tiptap h3, div.tiptap h4"
      )
    )
      .filter((element) => element.id)
      .map((element) => {
        element.innerHTML = `${
          element.innerHTML
        }<div class="header-anchor-link" href="${fullNoteUrl}${
          referrer ? `?referrer=${referrer}` : ""
        }#${
          element.id
        }"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="header-anchor-widget-icon"><path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path><path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path></svg></div>`
      })

    // Add click handlers to jump tags
    const jumpTagEventHandlers = Array.from(
      document.querySelectorAll(".header-anchor-link")
    ).map((element) => {
      const handler = () => {
        navigator.clipboard.writeText(element.getAttribute("href") || "")
        dispatch(makeToast("success", "Link copied."))
      }
      element.addEventListener("click", handler)
      return handler
    })

    setDone(true)

    // Remove click handlers on unmount
    return function cleanup() {
      jumpTagEventHandlers.map((handler) =>
        window.removeEventListener("click", handler)
      )
    }
  }, [editorReady, dispatch, fullNoteUrl, done])
}
