import type { Cast, CastThread } from "@/types/farcaster"
import Image from "next/image"
import UserAvatar from "components/UserAvatar"
import ReactTimeAgo from "react-time-ago"
import Linkify from "react-linkify"
import {
  ChatBubbleLeftRightIcon,
  HeartIcon,
  ArrowPathIcon,
  ChevronUpIcon,
} from "@heroicons/react/24/outline"

import Farcaster from "public/publication/farcaster.png"
import { ReactNode, useEffect, useState } from "react"
import { ChevronDownIcon } from "@heroicons/react/24/solid"
import clsx from "clsx"
import useWindowDimensions from "hooks/useWindowDimensions"

type Props = {
  thread: CastThread
  nestedLevel?: number
  showNestedComments: boolean
}

export default function FarcasterCommentEntry(props: Props) {
  const dims = useWindowDimensions()

  const windowWidth = dims?.width || 640

  const MAX_NESTED_LEVEL = windowWidth <= 640 ? 3 : windowWidth <= 1024 ? 9 : 14

  console.log("Rendering comment entry for ", props.thread)
  const comment = props.thread.parent

  const [showNestedComments, setShowNestedComments] = useState(
    props.showNestedComments
  )

  const [collapsed, setCollapsed] = useState(false)

  useEffect(() => {
    console.log(
      "Showing nested comments!",
      showNestedComments,
      props.nestedLevel
    )
  }, [showNestedComments])

  // Sometimes this is null? Don't know why.
  if (!comment) return null

  const getReactions = (cast: Cast) => cast.reactions.count + cast.replies.count

  const children = [...(props.thread.children || [])].sort(
    (a, b) => getReactions(b.parent) - getReactions(a.parent)
  )

  const CastLink = ({
    children,
    className,
  }: {
    children: ReactNode
    className?: string
  }) => (
    <a
      href={`https://warpcast.com/${comment.author.username}/${comment.hash}`}
      className={className}
      target="_blank"
      rel="noreferrer"
    >
      {children}
    </a>
  )

  const nestedLevel = props.nestedLevel ?? 0

  return (
    <div className="overflow-x-auto">
      <div>
        {(showNestedComments || nestedLevel <= MAX_NESTED_LEVEL) && (
          <div
            className={clsx(
              showNestedComments || nestedLevel <= MAX_NESTED_LEVEL
                ? "mb-2"
                : "mb-8",

              "relative "
            )}
          >
            {children.length > 0 && (
              <div
                aria-hidden="true"
                className="absolute top-0 left-5 border-l border-gray-150 h-full"
              ></div>
            )}
            <div className="relative flex items-start space-x-3">
              <>
                <div className="relative">
                  <UserAvatar
                    useRegularImgComponent
                    className="inline-block h-10 w-10 rounded-full"
                    url={comment?.author?.pfp?.url}
                  />
                  <Image
                    width={20}
                    height={20}
                    className="absolute -right-1 -bottom-1"
                    src={Farcaster}
                    alt="Farcaster"
                  />
                </div>
                <div className="min-w-min flex-1">
                  <div
                    className="flex flex-row justify-between group hover:cursor-pointer "
                    onClick={() => setCollapsed(!collapsed)}
                  >
                    <div className="flex flex-col">
                      <div className="text-sm text-custom-900 font-medium dont-break-out">
                        {comment.author.username}
                      </div>
                      <p className="mt-0.5 text-xs text-custom-400">
                        Commented{" "}
                        <ReactTimeAgo date={comment.timestamp} locale="en-US" />{" "}
                        on{" "}
                        <CastLink className="underline text-custom-400 hover:text-custom-500 cursor-pointer">
                          Farcaster
                        </CastLink>
                      </p>
                    </div>
                    <div className="group-hover:block hidden">
                      {collapsed ? (
                        <ChevronDownIcon className="h-5 w-5 text-custom-400" />
                      ) : (
                        <ChevronUpIcon className="h-5 w-5 text-custom-400" />
                      )}
                    </div>
                  </div>
                  {!collapsed && (
                    <div className="mt-2 text-sm">
                      <p className="prose prose-sm whitespace-pre-line text-custom-500 ">
                        <Linkify
                          componentDecorator={(
                            decoratedHref,
                            decoratedText,
                            key
                          ) => (
                            <a
                              target="blank"
                              href={decoratedHref}
                              key={key}
                              className="dont-break-out"
                            >
                              {decoratedText}
                            </a>
                          )}
                        >
                          {comment.text}
                        </Linkify>
                      </p>
                    </div>
                  )}

                  <div className="flex justify-between pt-2">
                    <div className="flex justify-start space-x-6">
                      <CastLink className="mt-0.5 text-sm font-medium text-custom-400 hover:text-red-500">
                        <HeartIcon className="h-4 w-4 inline-block" />{" "}
                        {comment.reactions.count}
                      </CastLink>

                      <CastLink className="mt-0.5 text-sm font-medium text-custom-400 hover:text-green-500">
                        <ArrowPathIcon className="h-4 w-4 inline-block" />{" "}
                        {comment.recasts.count}
                      </CastLink>

                      <CastLink className="mt-0.5 text-sm font-medium text-custom-400 hover:text-purple-500">
                        <ChatBubbleLeftRightIcon className="h-4 w-4 inline-block " />{" "}
                        {comment.replies.count}
                      </CastLink>
                    </div>
                  </div>
                </div>
              </>
            </div>

            {!collapsed && (
              <div
                className={clsx(
                  showNestedComments ? "ml-0" : "ml-9",

                  "mt-4"
                )}
              >
                {children?.map((child) => (
                  <FarcasterCommentEntry
                    key={child?.parent?.hash}
                    thread={{ parent: child.parent, children: child.children }}
                    nestedLevel={nestedLevel + 1}
                    showNestedComments={showNestedComments}
                  />
                ))}
              </div>
            )}
          </div>
        )}
      </div>

      {nestedLevel > MAX_NESTED_LEVEL && !showNestedComments && (
        <div
          onClick={() => setShowNestedComments(true)}
          className="cursor-pointer relative mb-8 text-gray-500 "
        >
          <ChevronDownIcon className="h-4 w-4 inline-block mr-1" />
          Show nested comments
        </div>
      )}
    </div>
  )
}
