import { useScroll } from "framer-motion"
import React, { useEffect, useState } from "react"

/**
 * This constant controls how many scrolls before the modal fades in all the way.
 * This is to prevent the modal from fading in too quickly for shorter posts, or too slowly for longer posts.
 * This is now a fixed value instead of the previous calculation that depended on post length.
 * Note however that a scroll of the scroll wheel or even when pushing the arrow key is NOT
 * necessarily equivalent to 1 step, as due to browser smoothing a single scroll can register as multiple
 * opacity steps, so this results in a gradual fade in without requiring the user to actually scroll e.g. 7 times.
 */
const opacitySteps = 7

/**
 * The point in the post at which the modal fades in.
 */
const scrollThresholdForShortPosts = 0.5
const scrollThresholdForLongPosts = 0.2
const longPostThreshold = 3000

/**
 * If we're scrolling down, increment the opacity by the steps in the constant.
 * If we're scrolling up, decrement the opacity by the steps in the constant.
 * @param currentOpacity
 * @param scrollingDown
 * @returns
 */
function calculateNextOpacity(currentOpacity: number, scrollingDown: boolean) {
  const stepChange = 1 / opacitySteps

  const nextStep = scrollingDown
    ? currentOpacity + stepChange
    : currentOpacity - stepChange

  // Opacity should never be below 0 or above 1.
  return Math.max(0, Math.min(1, nextStep))
}

/**
 * Helper hook to determine when we fade in the subscribe modal on the page,
 * when the reader scrolls down.
 *
 * contentRef is the ref of the editor content. We use this to determine
 * at what point do we fade in.
 *
 * We can not use the window scroll position because occasionally there is a massive number of comments on a page,
 * and we don't want to fade in the subscribe modal when the user is scrolling through comments.
 */
const useFadeinSubscribeModal = (
  contentRef: React.MutableRefObject<null | HTMLDivElement>
) => {
  const [opacity, setOpacity] = useState(0)
  const { scrollY } = useScroll({
    target: contentRef,
  })
  const [pointerEvents, setPointerEvents] = useState<"none" | "auto">("none")

  useEffect(() => {
    const contentHeight = contentRef.current?.scrollHeight || 0
    console.log("useFadeinSubscribeModal contentHeight", contentHeight)
    const threshold =
      contentHeight > longPostThreshold
        ? scrollThresholdForLongPosts * contentHeight
        : scrollThresholdForShortPosts * contentHeight
    const stepHeight = (contentHeight - threshold) / opacitySteps

    const unsubscribeSync = scrollY.onChange((y) => {
      const scrollingDown = scrollY.getPrevious() < scrollY.get()

      const shouldFadeIn = y > threshold

      if (!shouldFadeIn) {
        setOpacity(0)
        return
      }

      setOpacity((currentOpacity) => {
        const nextOpacity = calculateNextOpacity(currentOpacity, scrollingDown)

        console.log("useFadeinSubscribeModal opacity", {
          scrollY: y,
          threshold,
          stepHeight,
          currentOpacity,
          nextOpacity,
        })

        return nextOpacity
      })

      setPointerEvents(y > 0 ? "auto" : "none")
    })

    return () => {
      unsubscribeSync()
    }
  }, [scrollY, contentRef])

  return { opacity, pointerEvents }
}

export default useFadeinSubscribeModal
