import { Collectible } from "@/types/highlights"
import { updateHighlight } from "api_routes/highlights"
import { selectCurrentNote } from "features/noteSlice"
import { retrieveHighlights } from "features/pageSlice"
import useUserCollectingHighlight from "hooks/useUserCollectingHighlight"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import { useAppDispatch } from "store"
import { useAnalytics } from "hooks/useAnalytics"
import { getChainId } from "@/util/crypto"
import { useWaitForTransactionReceipt } from "wagmi"

export default function useUpdateCollectibleState(props: {
  collectible: Collectible | undefined
}) {
  const note = useSelector(selectCurrentNote)
  const dispatch = useAppDispatch()

  const isCollectiblePending = useUserCollectingHighlight(props.collectible)

  const waitForTransaction = useWaitForTransactionReceipt({
    hash: isCollectiblePending
      ? (props.collectible?.txHash as `0x${string}`)
      : undefined,
    chainId:
      isCollectiblePending && props.collectible?.chain
        ? getChainId(props.collectible?.chain)
        : undefined,
    confirmations: 1,
    pollingInterval: 1000,
  })

  const { track } = useAnalytics()

  useEffect(() => {
    if (!isCollectiblePending) {
      console.log(
        "Collectible is not pending, so no need to update state.",
        props.collectible
      )
      return
    }

    if (!waitForTransaction.data || !props.collectible?.id) {
      console.warn("Not yet ready to update collectible state.", {
        waitForTransaction,
        hl: props.collectible,
        isCollectiblePending,
      })
      return
    }

    console.log("Updating collectible state to COMPLETE...", props.collectible)

    // Toggle the collectible to COMPLETE.
    updateHighlight(props.collectible.id).then(() => {
      // After the collectible is updated, we need to update the page collectibles
      // which refreshes the counters (supply, etc) on the page.
      if (!note?.id || !props.collectible?.id) {
        console.error("No note id found.", note)
        return
      }

      track("collectible mint successful", {
        collectible: props.collectible,
      })

      dispatch(retrieveHighlights(note.id))
    })

    console.log(
      "TRANSACTION is successful, updating collectible state...",
      waitForTransaction,
      props.collectible
    )
  }, [waitForTransaction.isSuccess])

  useEffect(() => {
    if (!waitForTransaction.error) {
      return
    }
    track("collectible mint failed", {
      collectible: props.collectible,
      error: waitForTransaction.error,
    })
  }, [waitForTransaction.error])
}
