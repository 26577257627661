import { Blog, GetNoteResp } from "@types"
import { EnrichedGatingRuleGroup } from "@/types/gatingRules"
import TitleAndSubtitle from "./TitleAndSubtitle"
import RightSide from "./RightSide"

interface Props {
  currentNote: GetNoteResp
  blog: Blog
  loading: boolean
  hideComments: boolean
  hasAccess: boolean
  gatingRuleGroups: EnrichedGatingRuleGroup[]
}

export default function PostHeader(props: Props) {
  return (
    <div className="lg:flex lg:items-center lg:justify-between">
      <div className="flex-1 min-w-0">
        <TitleAndSubtitle currentNote={props.currentNote} blog={props.blog} />
        <RightSide
          currentNote={props.currentNote}
          blog={props.blog}
          hasAccess={props.hasAccess}
          gatingRuleGroups={props.gatingRuleGroups}
        />
      </div>
    </div>
  )
}
