import { Note } from "@types"
import CategoryBadges from "components/CategoryBadges"
import useBlogUrl from "hooks/useBlogUrl"

import { selectBlog } from "features/blogSlice"
import { useSelector } from "react-redux"
import { EnrichedGatingRuleGroup } from "@/types/gatingRules"
import PostFooterBoxes from "components/public/BlogTheme/PostPage/Footer/Boxes"
import Comments from "../Footer/Comments"
import CollectibleLogic from "./CollectibleLogic"
import PostContentEditor from "./PostContentEditor"
import clsx from "clsx"
import useEditorWrapper from "hooks/useEditorWrapper"
import { forwardRef } from "react"

interface modelProps {
  currentNote: Note
  hideComments: boolean
  hasAccess: boolean
  gatingRuleGroups: EnrichedGatingRuleGroup[]
  setEditorLoaded?(val: boolean): void
}

// eslint-disable-next-line react/display-name
const PostContent = forwardRef((props: modelProps, ref: any) => {
  const blog = useSelector(selectBlog)
  const blogUrl = useBlogUrl({ blog })

  const { editor, editorReady, initialContent } = useEditorWrapper(
    props.currentNote.json,
    props.currentNote.text
  )

  if (props.setEditorLoaded && editorReady) props.setEditorLoaded(editorReady)

  const hasGatingRules =
    props.gatingRuleGroups && props.gatingRuleGroups.length > 0

  return (
    <div
      className={clsx(
        !hasGatingRules || props.hasAccess ? "" : "blur-md backdrop-blur-md"
      )}
    >
      <CollectibleLogic editor={editor} />

      <div className="pb-5 relative" ref={ref}>
        <PostContentEditor
          editor={editor}
          currentNote={props.currentNote}
          editorReady={editorReady}
          initialContent={initialContent}
        />

        <div className="clear-both"></div>

        {props.hasAccess && <PostFooterBoxes />}

        <CategoryBadges
          className="pt-4"
          note={props.currentNote}
          showAddCategory={false}
          showCategories={true}
          communityColor="yellow"
          categoryColor="green"
          clickable={true}
          clickableBaseUrl={blogUrl.url}
        />
      </div>

      {!props.hideComments && <Comments currentNote={props.currentNote} />}
    </div>
  )
})

export default PostContent
