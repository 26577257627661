import { selectCurrentNote } from "features/noteSlice"
import { useSelector } from "react-redux"

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ")
}

export default function ArweaveBanner() {
  const note = useSelector(selectCurrentNote)
  if (!note?.arweaveId) return null
  return (
    <nav
      className="space-y-1 border-b border-l border-r border-custom-100 rounded-b-lg grid shadow-sm transition-all"
      aria-label="Arweave banner"
    >
      <a
        href={`https://viewblock.io/arweave/tx/${note?.arweaveId}`}
        target="_blank"
        className={classNames(
          "text-custom-400 justify-between transition-all",
          "flex items-center px-3 py-2 text-sm font-medium rounded-md flex-col truncate"
        )}
        rel="noreferrer"
      >
        <span className="truncate font-bold text-xs tracking-widest text-custom-200">
          ARWEAVE TX
        </span>
        <span className="truncate text-xs tracking-tight sm:tracking-wide text-custom-300">
          {" "}
          {note?.arweaveId}
        </span>
      </a>
    </nav>
  )
}
