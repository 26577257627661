import UAuth from "@uauth/js"
import { useAnalytics } from "hooks/useAnalytics"
import { useAppDispatch } from "store"
import { UserInfo } from "@uauth/js"
import { useEffect, useState } from "react"
import { loginUnstoppableDomainsUser } from "../features/userSlice"
import { ArrowLongRightIcon } from "@heroicons/react/24/solid"

const uauth = new UAuth({
  clientID: "99ca1d1d-8139-4ffe-9657-21bbe7985df0",
  redirectUri: process.env.NEXT_PUBLIC_UNSTOPPABLE_DOMAINS_REDIRECT_URI || "",
  scope: "openid wallet email:optional",
})

export default function UnstoppableLogin({}) {
  const { track } = useAnalytics()
  const dispatch = useAppDispatch()

  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [loggedInUser, setLoggedInUser] = useState<UserInfo | null>(null)

  useEffect(() => {
    console.log("Unstoppable Domains user details", loggedInUser)

    if (!loggedInUser) return

    dispatch(
      loginUnstoppableDomainsUser({
        usernameUnstoppable: loggedInUser.sub,
        email: loggedInUser.email,
      })
    )
  }, [loggedInUser])

  const handleLoginButtonClick = async () => {
    setIsLoading(true)
    setErrorMessage(null)
    try {
      await uauth.loginWithPopup()

      // If login was successful, let's get the user info and set it.
      const user = await uauth.user()
      setLoggedInUser(user)
      track("Login with Unstoppable Domains succeeded", { domain: user.sub })
    } catch (error) {
      console.error("Error occurred logging in with Unstoppable Domains", error)
      track("Login with Unstoppable Domains failed")
      setIsLoading(false)
    }

    setIsLoading(false)
  }

  if (
    process.env.NEXT_PUBLIC_VERCEL_ENV === "production" &&
    !process.env.NEXT_PUBLIC_UNSTOPPABLE_DOMAINS_REDIRECT_URI
  ) {
    console.error("No NEXT_PUBLIC_UNSTOPPABLE_DOMAINS_REDIRECT_URI found")
    return null
  }

  return (
    <>
      {errorMessage && <div>{errorMessage}</div>}
      <div className="flex justify-center items-center pt-4">
        <button
          type="button"
          className="items-center w-full text-gray-500 px-2 py-1 text-xs focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          onClick={() => {
            track("Login with Unstoppable Domains button clicked")
            handleLoginButtonClick()
          }}
        >
          <div className="flex justify-center items-center">
            {isLoading && (
              <svg
                className="animate-spin h-5 w-5 mr-3 text-white"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="9"
                  fill="none"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            )}
            <p className="">Login with Unstoppable Domains </p>
            <ArrowLongRightIcon className="h-5 w-5 ml-2" />
          </div>
        </button>
      </div>
    </>
  )
}
