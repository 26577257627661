import { useEffect, useState, useRef } from "react"
import { useAnalytics } from "hooks/useAnalytics"
import { useSelector } from "react-redux"
import { selectUser } from "features/userSlice"
import { updateComment } from "features/commentSlice"
import useUser from "hooks/useUser"
import RegisterModal from "components/RegisterModal"
import Button from "components/Button"
import { useAppDispatch } from "store"
import useDebounce from "hooks/useDebounce"
import useColor from "hooks/useColor"
import { CommentWithUser } from "@types"
import Banner from "components/Banner"

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ")
}

interface Props {
  editExistingComment: CommentWithUser
  setShowCommentBox: (show: boolean) => void
}

export default function EditComment({
  editExistingComment,
  setShowCommentBox,
}: Props) {
  const textareaRef = useRef<HTMLTextAreaElement>(null)
  const [commentText, setCommentText] = useState(editExistingComment.text)
  const { loggedIn } = useUser()
  const dispatch = useAppDispatch()
  const user = useSelector(selectUser)
  const [showRegisterModal, setShowRegisterModal] = useState(false)
  const [attemptEditComment, setAttemptEditComment] = useState(false)
  const [buttonLoading, setButtonLoading] = useState(false)
  const { track } = useAnalytics()
  const { primary } = useColor()
  const [showCancelButton, setShowCancelButton] = useState(true)
  const [showErrorBannerForCommentId, setShowErrorBannerForCommentId] =
    useState("")

  const debouncedText = useDebounce(commentText, 5000)

  useEffect(() => {
    if (!debouncedText) return
    track("comment text changed")
  }, [debouncedText])

  useEffect(() => {
    if (!textareaRef.current) return
    textareaRef.current.style.height = "0px"
    const scrollHeight = textareaRef.current.scrollHeight
    textareaRef.current.style.height = scrollHeight + "px"
  }, [commentText])

  useEffect(() => {
    async function editComment() {
      if (!editExistingComment.id) {
        return
      }

      try {
        await dispatch(updateComment(editExistingComment.id, commentText))
        setCommentText("")
        setShowCommentBox(false)
      } catch (err) {
        setShowErrorBannerForCommentId(editExistingComment.id)
        setShowCancelButton(true)
      }

      setButtonLoading(false)
    }

    if (!attemptEditComment) return
    setButtonLoading(true)
    setShowCancelButton(false)

    if (loggedIn) {
      editComment()
      setAttemptEditComment(false)
    } else {
      setShowRegisterModal(true)
    }
  }, [attemptEditComment, commentText, dispatch, loggedIn])

  return (
    <>
      <RegisterModal open={showRegisterModal} setOpen={setShowRegisterModal} />
      <div className="flex items-start">
        <div className="min-w-0 flex-1">
          <div className="relative">
            <div
              style={{
                // @ts-ignore
                "--tw-ring-color": primary,
              }}
              className="border rounded-lg shadow-sm overflow-hidden focus-within:ring-1"
            >
              <label htmlFor="comment" className="sr-only">
                Add your comment
              </label>
              <textarea
                ref={textareaRef}
                name="comment"
                id="comment"
                className="block w-full py-3 border-0 resize-none focus:ring-0 sm:text-sm overflow-hidden"
                placeholder="Add your comment..."
                value={commentText}
                onChange={(e) => {
                  e.preventDefault()
                  setCommentText(e.target.value)
                }}
              />

              {/* Spacer element to match the height of the toolbar */}
              {commentText && (
                <div className="py-2" aria-hidden="true">
                  {/* Matches height of button in toolbar (1px border + 36px content height) */}
                  <div className="py-px">
                    <div className="h-9" />
                  </div>
                </div>
              )}
            </div>

            {commentText && (
              <div className="absolute bottom-0 inset-x-0 pl-3 pr-2 py-2 flex justify-end">
                <div className="flex items-center space-x-5"></div>
                <div className="flex-shrink-0 mr-2">
                  {showCancelButton && (
                    <Button
                      onClick={() => {
                        setShowCommentBox(false)
                      }}
                      text="Cancel"
                      replaceClassName="inline-flex justify-center px-3.5 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    />
                  )}
                </div>
                <div className="flex-shrink-0">
                  <Button
                    loading={buttonLoading}
                    onClick={() => {
                      if (!commentText) return
                      setAttemptEditComment(true)
                    }}
                    text="Update"
                  />
                </div>
              </div>
            )}
          </div>
          {showErrorBannerForCommentId &&
            showErrorBannerForCommentId == editExistingComment.id && (
              <div className="mt-2">
                <Banner
                  className="mb-2"
                  type="error"
                  text={"Error editing comment."}
                />
              </div>
            )}
        </div>
      </div>
    </>
  )
}
