import { Blog, GetNoteResp } from "@types"
import { useSelector } from "react-redux"
import { selectCommunities } from "features/blogSlice"
import useBlogUrl from "hooks/useBlogUrl"
import Link from "next/link"

interface Props {
  currentNote: GetNoteResp
  blog: Blog
}

export default function TitleAndSubtitle(props: Props) {
  const { customDomainOrRelativeUrl } = useBlogUrl({ blog: props.blog })

  const communities = useSelector(selectCommunities)

  const community = communities?.find(
    (c) => c.id === props.currentNote.communityId
  )

  return (
    <>
      {community && (
        <Link
          href={`${customDomainOrRelativeUrl}community/${community.name}`}
          className={`cursor-pointer text-primary-900 hover:text-primary-500 uppercase tracking-wide text-md font-bold`}
        >
          {community.name}
        </Link>
      )}
      <h1
        className="mt-2 text-2xl font-bold text-custom-900 leading-7 sm:text-4xl "
        dir="auto"
      >
        {props.currentNote.title || "Untitled post"}
      </h1>
      {props.currentNote.subtitle && (
        <h2
          className="mt-2 text-lg text-custom-500 leading-7 sm:text-xl"
          dir="auto"
        >
          {props.currentNote.subtitle}
        </h2>
      )}
    </>
  )
}
