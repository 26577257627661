import { useEffect, useState, useRef } from "react"
import { useAnalytics } from "hooks/useAnalytics"
import { useSelector } from "react-redux"
import { selectUser } from "features/userSlice"
import UserAvatar from "./UserAvatar"
import { createNewComment } from "features/commentSlice"
import useUser from "hooks/useUser"
import RegisterModal from "components/RegisterModal"
import Button from "components/Button"
import { useAppDispatch } from "store"
import useDebounce from "hooks/useDebounce"
import useColor from "hooks/useColor"
import { selectBlog } from "@/features/blogSlice"

interface Props {
  replyToCommentId?: string
  setShowCommentBox?: (show: boolean) => void
}

export default function AddComment({
  replyToCommentId,
  setShowCommentBox,
}: Props) {
  const textareaRef = useRef<HTMLTextAreaElement>(null)
  const [commentText, setCommentText] = useState("")
  const { loggedIn } = useUser()
  const dispatch = useAppDispatch()
  const user = useSelector(selectUser)
  const [showRegisterModal, setShowRegisterModal] = useState(false)
  const [attemptSubmitComment, setAttemptSubmitComment] = useState(false)
  const [buttonLoading, setButtonLoading] = useState(false)
  const { track } = useAnalytics()
  const { primary } = useColor()

  const debouncedText = useDebounce(commentText, 5000)
  const blog = useSelector(selectBlog)

  useEffect(() => {
    if (!debouncedText) return
    track("comment text changed")
  }, [debouncedText])

  useEffect(() => {
    if (!textareaRef.current) return
    textareaRef.current.style.height = "0px"
    const scrollHeight = textareaRef.current.scrollHeight
    textareaRef.current.style.height = scrollHeight + "px"
  }, [commentText])

  useEffect(() => {
    async function submitComment() {
      await dispatch(createNewComment(commentText, replyToCommentId))
      setButtonLoading(false)
      setCommentText("")
      setShowCommentBox && setShowCommentBox(false)
    }

    if (!attemptSubmitComment) return
    setButtonLoading(true)

    if (loggedIn) {
      submitComment()
      setAttemptSubmitComment(false)
      //dispatch(createNewComment(commentText))
    } else {
      setShowRegisterModal(true)
    }
  }, [attemptSubmitComment, commentText, dispatch, loggedIn])

  if (
    blog.disable_comments === true ||
    blog.disable_comments === "on-platform"
  ) {
    return null
  }

  return (
    <>
      <RegisterModal open={showRegisterModal} setOpen={setShowRegisterModal} />
      <div className="flex items-start space-x-4">
        <div className="flex-shrink-0">
          <UserAvatar
            className="inline-block h-10 w-10 rounded-full text-custom-300 "
            url={user.avatar_url}
            noSSR
          />
        </div>
        <div className="min-w-0 flex-1">
          <div className="relative">
            <div
              style={{
                // @ts-ignore
                "--tw-ring-color": primary,
              }}
              className="border rounded-lg shadow-sm overflow-hidden focus-within:ring-1"
            >
              <label htmlFor="comment" className="sr-only">
                Add your comment
              </label>
              <textarea
                ref={textareaRef}
                name="comment"
                id="comment"
                className="block w-full py-3 border-0 resize-none focus:ring-0 sm:text-sm overflow-hidden"
                placeholder="Add your comment..."
                value={commentText}
                onChange={(e) => {
                  e.preventDefault()
                  setCommentText(e.target.value)
                }}
              />

              {/* Spacer element to match the height of the toolbar */}
              {commentText && (
                <div className="py-2" aria-hidden="true">
                  {/* Matches height of button in toolbar (1px border + 36px content height) */}
                  <div className="py-px">
                    <div className="h-9" />
                  </div>
                </div>
              )}
            </div>

            {commentText && (
              <div className="absolute bottom-0 inset-x-0 pl-3 pr-2 py-2 flex justify-between">
                <div className="flex items-center space-x-5"></div>
                <div className="flex-shrink-0">
                  <Button
                    loading={buttonLoading}
                    onClick={() => {
                      if (!commentText) return
                      setAttemptSubmitComment(true)
                    }}
                    //style={{ background: primary, borderColor: primary }}
                    text="Post"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
