import { useSelector } from "react-redux"
import useHydrateHighlights from "hooks/useHydrateHighlights"
import CollectExistingHighlightPortal from "components/Collectibles/CollectExistingHighlightPortal"
import { ExtendedCollectible } from "@/types/highlights"
import useUpdateCollectibleState from "hooks/collectibles/useUpdateCollectibleState"
import { selectAllCollectibles } from "features/pageSlice"
import useBlogHasCollectibles from "hooks/collectibles/useBlogHasCollectibles"

/**
 * Given a collectible, update the state.
 * If it's completed, it does nothing. If it's pending, it
 * checks if the block has cleared then saves it to the db.
 */
function UpdateHighlightState(props: { collectible: ExtendedCollectible }) {
  console.log("UpdateHighlightState in Post.tsx", props.collectible)
  useUpdateCollectibleState({ collectible: props.collectible.collectible })

  return null
}

export default function CollectibleLogic({ editor }: { editor: any }) {
  const blogHasCollectibles = useBlogHasCollectibles()
  const allCollectibles = useSelector(selectAllCollectibles)
  const highlightElements = useHydrateHighlights({ editor })

  return (
    <>
      {blogHasCollectibles &&
        allCollectibles &&
        allCollectibles.map((c, idx) => (
          <UpdateHighlightState key={idx} collectible={c} />
        ))}

      {blogHasCollectibles &&
        highlightElements &&
        Array.from(highlightElements).map((element, idx) => (
          <CollectExistingHighlightPortal
            key={idx}
            editor={editor}
            elem={element}
          />
        ))}
    </>
  )
}
