import { GetNoteResp } from "@types"
import { PostPlaceholder } from "components/Placeholder"

import PostContent from "./PostContent"
import ReaderGate from "components/ReaderGates/ReaderGate"

import FadeInModal from "components/SubscribeModal/FadeInModal"
import { useRef } from "react"

export default function PostSkeletonBody(props: {
  loading: boolean
  hasAccess: boolean
  currentNote: GetNoteResp
  hideComments: boolean
  gatingRuleGroups: any
  hasGatingRules: boolean
  setEditorLoaded?(val: boolean): void
}) {
  const contentRef = useRef<HTMLDivElement | null>(null)

  console.log("Content Ref", contentRef)

  return (
    <div className="px-4 sm:px-0 relative">
      {props.loading === true && <PostPlaceholder />}
      {props.loading === false && (
        <>
          {props.hasGatingRules && !props.hasAccess && (
            <div>
              <ReaderGate
                noteId={props.currentNote.id}
                gateContentType={"POST"}
                embedId={null}
              />
            </div>
          )}
          <PostContent
            ref={contentRef}
            currentNote={props.currentNote}
            hideComments={props.hideComments || !props.hasAccess}
            key={props.currentNote.id}
            hasAccess={props.hasAccess || false}
            gatingRuleGroups={props.gatingRuleGroups}
            setEditorLoaded={props.setEditorLoaded}
          />

          <FadeInModal contentRef={contentRef} />
        </>
      )}
    </div>
  )
}
