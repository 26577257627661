import { useEffect, useRef, useState } from "react"

type HeadingType = { id: string; text: string; level: number }
const useHeadings = () => {
  const [headings, setHeadings] = useState<HeadingType[]>([])
  useEffect(() => {
    const elements = Array.from(
      document.querySelectorAll(
        "div.tiptap h1, div.tiptap h2, div.tiptap h3, div.tiptap h4"
      )
    )
      .filter((element) => element.id)
      .map((element) => ({
        id: element.id,
        text: element.textContent ?? "",
        level: Number(element.tagName.substring(1)),
      }))
    setHeadings(elements)
  }, [])
  return headings
}

const useScrollSpy = (ids: string[], options: IntersectionObserverInit) => {
  const [activeId, setActiveId] = useState<string>()
  const observer = useRef<IntersectionObserver>()
  useEffect(() => {
    const elements = ids.map((id) => document.getElementById(id))
    observer.current?.disconnect()
    observer.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry?.isIntersecting) {
          setActiveId(entry.target.id)
        }
      })
    }, options)
    elements.forEach((el) => {
      if (el) {
        observer.current?.observe(el)
      }
    })
    return () => observer.current?.disconnect()
  }, [ids, options])
  return activeId
}

export const TableOfContents = () => {
  const headings = useHeadings()
  const activeId = useScrollSpy(
    headings.map(({ id }) => id),
    { rootMargin: "0% 0% -90% 0%" }
  )

  // This is used to keep the post contents centered in the flexbox
  if (headings.length === 0) return <div className="toc-filler" />

  return (
    <nav className="toc-filler toc">
      <h3>Contents</h3>
      <ul>
        {headings.map((heading) => (
          <li key={heading.id}>
            <a
              style={{
                marginLeft: `${heading.level - 2}em`,
              }}
              data-active={activeId === heading.id}
              href={`#${heading.id}`}
              onClick={(e) => {
                e.preventDefault()
                document.querySelector(`#${heading.id}`)?.scrollIntoView({
                  behavior: "smooth",
                })
              }}
            >
              {heading.text}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  )
}

export default TableOfContents
