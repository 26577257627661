import { Editor } from "@tiptap/react"
import { selectBlog } from "features/blogSlice"
import { selectCurrentNote } from "features/noteSlice"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"

import { retrieveHighlights, selectHighlights } from "features/pageSlice"
import useUser from "./useUser"
import { Collectible } from "@/types/highlights"
import useBlogHasCollectibles from "./collectibles/useBlogHasCollectibles"
import { userCollectingHighlight } from "./useUserCollectingHighlight"
import { useAccount } from "wagmi"
import { useAppDispatch } from "store"

type Props = {
  editor: Editor | null
}

export default function useHydrateCollectibles(props: Props) {
  const blog = useSelector(selectBlog)
  const note = useSelector(selectCurrentNote)

  const highlights = useSelector(selectHighlights)
  const dispatch = useAppDispatch()

  const editor = props.editor

  const [domElems, setDomElems] = useState<NodeListOf<Element>>()

  const user = useUser()
  const account = useAccount()

  console.log("This is the users wallet..")

  const blogHasCollectibles = useBlogHasCollectibles()

  useEffect(() => {
    if (!blogHasCollectibles || !note?.id) return

    console.log("Hydrating highlights...")

    dispatch(retrieveHighlights(note.id))
  }, [note?.id, blogHasCollectibles])

  useEffect(() => {
    if (!editor || !highlights) return

    const isCollectibleComplete = (hl: Collectible) =>
      hl.status === "COMPLETE" && hl.contractAddress !== ""

    highlights
      .filter(
        ({ collectible }) =>
          isCollectibleComplete(collectible) ||
          userCollectingHighlight(collectible, user, account.address)
      )
      .map((h) => {
        const tr = editor.state.tr

        if (h.collectible.position.from === h.collectible.position.to) {
          console.error("Collectible is empty!")
          return
        }

        // If the `from` or `to` position exceeds the `nodeSize`, then we're likely dealing with a collectible highlight
        // that is behind a gate, and therefore appears to be past the size of the document.
        if (
          h.collectible.position.from > editor.state.doc.nodeSize ||
          h.collectible.position.to > editor.state.doc.nodeSize
        ) {
          console.error("Collectible text is past gated portion of post!")
          return
        }

        // Handle the case where someone edits a post and republishes a draft.
        // In this case, the collectible text will be different from the
        // text in the editor. We don't want to add the mark.
        if (
          editor.state.doc.textBetween(
            h.collectible.position.from,
            h.collectible.position.to
          ) !== h.collectible.text
        ) {
          console.error("Collectible text does not match!")
          return
        }

        // Add the mark. This will create the Collectible mark, which
        // will have a specific data-type that is queried below.
        tr.addMark(
          h.collectible.position.from,
          h.collectible.position.to,
          editor?.state.schema.marks.highlight.create({
            highlightId: h.collectible.id,
          })
        )

        editor?.view?.dispatch(tr)

        const domElements = document.querySelectorAll("[data-highlight-id]")

        setDomElems(domElements)
      })
  }, [JSON.stringify(highlights), editor, note?.id, blog?.id, account.address])

  return domElems
}
