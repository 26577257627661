import { GetNoteResp, Note } from "@types"

import AddComment from "components/AddComment"
import CommentFeed from "components/CommentFeed"

export default function Comments({
  currentNote,
}: {
  currentNote: GetNoteResp | Note
}) {
  return (
    <>
      <div className="" id="comments">
        <AddComment />
      </div>
      <div className="pt-8">
        <CommentFeed
          currentNote={currentNote}
          //key={props.currentNote.id + postSlug}
        />
      </div>
    </>
  )
}
