import { Editor } from "@tiptap/react"
import { useEffect, useRef, useState } from "react"
import { Transition } from "@headlessui/react"
import { usePopper } from "react-popper"
import ReactDOM from "react-dom"
import { useSelector } from "react-redux"
import { selectHighlights } from "features/pageSlice"
import clsx from "clsx"
import { XMarkIcon } from "@heroicons/react/24/solid"
import useUserCollectingHighlight from "hooks/useUserCollectingHighlight"
import ExistingHighlightCollect from "./Popover/Existing/ExistingCollect"
import PendingHighlightCollect from "./Popover/Pending/PendingCollect"
import useOutsideClick from "hooks/useOutsideClick"

import { useAnalytics } from "hooks/useAnalytics"

type Props = {
  editor: Editor | null
  highlightId: string | null
  isModalOpen: boolean
  referenceElement: HTMLElement | null | undefined
  setIsModalOpen: (isModalOpen: boolean) => void
}

export default function CollectExistingHighlightPopoverBtn(
  props: Props
): JSX.Element {
  const { track } = useAnalytics()
  const [isClient, setIsClient] = useState(false)
  const [popperElement, setPopperElement] = useState<
    HTMLDivElement | undefined | null
  >()
  const { styles, forceUpdate, attributes } = usePopper(
    props.referenceElement,
    popperElement,

    {
      strategy: "fixed",
      modifiers: [
        {
          name: "flip",
          options: {
            fallbackPlacements: ["bottom", "right"],
          },
        },
        {
          name: "preventOverflow",
          options: {
            altAxis: true,
            tether: false,
          },
        },
      ],
    }
  )

  const ref = useRef(null)

  useOutsideClick(ref, () => props.setIsModalOpen(false))

  useEffect(() => setIsClient(true), [])

  // We need to use a React Portal to render the popover outside of the editor,
  // otherwise we'll get issues with the z-index and overlapping.
  // So, we need to get the root element (#para-document),
  // and then render the popover inside of that.
  const portalElem = isClient ? document.getElementById("para-document") : null

  const highlights = useSelector(selectHighlights)
  const currentHighlight = highlights.find(
    (h) => h.collectible.id === props.highlightId
  )

  const isUserCollectingHighlight = useUserCollectingHighlight(
    currentHighlight?.collectible
  )
  console.log(
    "Rendering CollectExistingHighlightPopoverBtn",
    highlights,
    currentHighlight
  )

  useEffect(() => {
    console.log(
      "isUserCollectingHighlight in popoverbtn",
      isUserCollectingHighlight
    )
    if (isUserCollectingHighlight) {
      console.log(
        "Highlight collection in progress, so opening modal",
        isUserCollectingHighlight,
        currentHighlight
      )
      props.setIsModalOpen(true)
    }
  }, [isUserCollectingHighlight])

  useEffect(() => {
    if (!props.isModalOpen) return

    track("existing highlight collect modal opened", {
      ...currentHighlight?.collectible,
    })

    forceUpdate?.()
  }, [props.isModalOpen])

  // If the is in PENDING state still, then we should check to see if the block cleared
  // and if so, update the highlight in our database to COMPLETED.
  // useUpdateHighlightState({ highlight: currentHighlight?.highlight })

  return (
    <div className="relative inline">
      <>
        <span
          className={clsx("highlight-react cursor-pointer")}
          tabIndex={0}
          onBlur={() => {
            props.setIsModalOpen(false)
          }}
        >
          {isClient &&
            portalElem &&
            ReactDOM.createPortal(
              <div
                className="z-50"
                style={styles.popper}
                {...attributes.popper}
                ref={setPopperElement}
              >
                <Transition
                  className="relative z-50"
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                  //show={true}
                  show={props.isModalOpen}
                  // We need to refresh Popper here. When the transition begins,
                  // the DOM changes, so Popper needs to re-calculate proper positioning.
                  // If we don't do this then the popover can be pushed off screen (esp on mobile).
                  afterEnter={() => {
                    forceUpdate?.()
                  }}
                  beforeEnter={() => {
                    forceUpdate?.()
                  }}
                >
                  <div className="shadow-lg ring-1 ring-black ring-opacity-5 bg-white px-5 py-6 rounded-lg">
                    <div className="flex flex-row">
                      <XMarkIcon
                        className="h-5 w-5 text-gray-400 cursor-pointer"
                        onClick={() => {
                          props.setIsModalOpen(false)
                        }}
                      />
                    </div>
                    <div
                      className="relative grid gap-6 sm:gap-8 sm:p-2 "
                      ref={ref}
                    >
                      {props.editor &&
                        currentHighlight?.collectible &&
                        currentHighlight.collectible.status === "COMPLETE" && (
                          <ExistingHighlightCollect
                            highlight={currentHighlight?.collectible}
                          />
                        )}

                      {props.editor &&
                        currentHighlight?.collectible &&
                        currentHighlight.collectible.status === "PENDING" && (
                          <PendingHighlightCollect
                            pendingCollectible={currentHighlight?.collectible}
                          />
                        )}
                    </div>
                  </div>
                </Transition>
              </div>,

              portalElem as Element
            )}
        </span>
      </>
    </div>
  )
}
