import { Editor } from "@tiptap/react"
import { useEffect, useState } from "react"
import { createPortal } from "react-dom"
import Highlight from "./CollectExistingHighlightPopoverBtn"

export default function HighlightPortal(props: {
  editor: Editor | null
  elem: Element
}) {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const [referenceElement, setReferenceElement] = useState<
    HTMLElement | undefined | null
  >()

  useEffect(() => {
    setReferenceElement(props.elem as HTMLElement)

    const listener = () => setIsModalOpen(!isModalOpen)
    props.elem.addEventListener("click", listener)

    return () => props.elem.removeEventListener("click", listener)
  }, [props.elem])

  return createPortal(
    // Display the Highlight modal when the user clicks on the
    // highlighted text using a React portal.
    <Highlight
      referenceElement={referenceElement}
      setIsModalOpen={setIsModalOpen}
      editor={props.editor}
      highlightId={props.elem.getAttribute("data-highlight-id")}
      isModalOpen={isModalOpen}
    />,

    props.elem
  )
}
