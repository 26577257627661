import useBlogHasCollectibles from "hooks/collectibles/useBlogHasCollectibles"

import GatingRuleInfo from "./GatingRuleInfo"

import CollectPostPopoverBtn from "components/Collectibles/CollectPostPopoverBtn"
import ShareButton from "components/Editor/ShareButton/ShareButton"
import AuthorNames from "../../AuthorNames"
import AuthorAvatars from "../../AuthorAvatars"
import { Blog, GetNoteResp } from "@types"
import { EnrichedGatingRuleGroup } from "@/types/gatingRules"

export default function RightSide(props: {
  currentNote: GetNoteResp

  gatingRuleGroups: EnrichedGatingRuleGroup[]

  blog: Blog
  hasAccess: boolean
}) {
  const date = new Date(
    props.currentNote?.publishedAt ||
      props.currentNote?.updatedAt ||
      props.currentNote?.createdAt ||
      "2022-01-01"
  )

  const hasGatingRules =
    props.gatingRuleGroups && props.gatingRuleGroups.length > 0

  const blogHasCollectibles = useBlogHasCollectibles()

  return (
    <aside className="mt-1 sm:mt-0 sm:space-x-6 flex justify-between flex-col sm:flex-row">
      <div className="py-5">
        <div className="flex justify-between space-x-3">
          <div className="flex flex-1 space-x-1 justify-between">
            <div className="flex-shrink-0 justify-center flex flex-col">
              <AuthorAvatars note={props.currentNote} />
            </div>
            <div className="flex-1 min-w-0 flex flex-col justify-center -space-y-0.5">
              <div className="flex flex-row space-x-2">
                <AuthorNames blog={props.blog} note={props.currentNote} />
              </div>

              <div className="flex">
                <time
                  dateTime={date.toISOString()}
                  className="text-sm leading-7 flex align-middle text-custom-500"
                >
                  {new Intl.DateTimeFormat("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    timeZone: "UTC",
                  }).format(date)}
                </time>
                {/* Show a lock open or closed sign on gated posts, which when hovered/clicked on displays more information about the gates. */}

                {hasGatingRules && (
                  <GatingRuleInfo
                    gatingRuleGroups={props.gatingRuleGroups}
                    currentNote={props.currentNote}
                    hasAccess={props.hasAccess}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-row space-y-1 sm:space-y-0 sm:space-x-1 sm:justify-center items-end xs:items-center justify-between border-y border-custom-100 py-3 sm:py-0 sm:border-y-0">
        <ShareButton replaceClassName="bg-secondary justify-self-center align-middle focus:ring-primary-500 justify-center px-4 py-2 text-sm font-medium text-custom-500 border-custom-100 border shadow-sm rounded-md focus:outline-none focus:ring-2 cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed transition-all h-10  " />
        {blogHasCollectibles && <CollectPostPopoverBtn />}
      </div>
    </aside>
  )
}
