import { useEffect, useRef, useState } from "react"
import { SubscribeModalContents } from "./Modal"
import useFadeInSubscribeModal from "hooks/useFadeinSubscribeModal"
import { motion } from "framer-motion"
import useShowInitialSubscribeModal from "hooks/useShowInitialSubscribeModal"
import { useAnalytics } from "use-analytics"
import { useSelector } from "react-redux"
import { selectActiveSubscription } from "features/userSlice"

/**
 * The subscribe modal gradually fades-in when a user scrolls.
 */
export default function FadeInModal(props: {
  contentRef: React.RefObject<HTMLDivElement>
}) {
  // If the user dismisses this modal, never show it again.
  const [userDismissed, setUserDismissed] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const { opacity, pointerEvents } = useFadeInSubscribeModal(props.contentRef)
  const { track } = useAnalytics()
  const sub = useSelector(selectActiveSubscription)

  const { shouldShow, loading, dontShowAgain } =
    useShowInitialSubscribeModal("on-scroll")

  console.log("showSubscribeModal", shouldShow)

  // This is used to close the modal when the user clicks outside of it.
  const wrapperRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        setUserDismissed(true)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  useEffect(() => {
    const totalHeight =
      document.getElementById("paragraph-tiptap-editor")?.clientHeight || 0

    console.log("TipTap height", totalHeight)

    console.log(
      "Fade in modal, displayed on scroll",
      shouldShow,
      loading,
      totalHeight
    )

    if (!shouldShow) return
    if (loading) return

    // This is a safeguard to prevent the modal from showing on pages that are
    // too short.
    //
    // This means the page height needs to be greater than this amount in order for
    // the modal to show.
    if (totalHeight < 1000) return

    setShowModal(true)
    track("subscribe modal shown on scroll")
  }, [shouldShow, loading])

  /*
   * We need to handle the case where a user subscribes on the subscribe-landing
   * popup, then scrolls down. We don't want to show the fade-in popup then.
   */
  useEffect(() => {
    // If the modal is currently open (eg pointerEvents is auto),
    // do onthing.
    if (!sub || pointerEvents === "auto") return

    setShowModal(false)
    dontShowAgain()
  }, [sub, showModal])

  useEffect(() => {
    if (!userDismissed) return

    dontShowAgain()
  }, [userDismissed])

  console.log("Rendering this!")

  if (userDismissed || !showModal) return null

  console.log("Rendering fade-in modal", opacity)

  return (
    <div>
      <motion.div
        // We need to remove this from the DOM, so the page does not expand at the bottom.
        className="absolute z-50"
        style={{
          opacity,

          // We want to make sure the modal is not clickable until it's
          // visible.
          pointerEvents,
        }}
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-85 transition-opacity" />

        {/* This element is to trick the browser into centering the modal contents. */}
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div className="fixed inset-0">
          <div className="relative">
            <SubscribeModalContents
              setOpen={(val) => setUserDismissed(!val)}
              appearOnScroll
              fadeInClickRef={wrapperRef}
            />
          </div>
        </div>
      </motion.div>
    </div>
  )
}
