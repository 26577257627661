import { Blog, GetNoteResp } from "@types"
import { EnrichedGatingRuleGroup } from "@/types/gatingRules"
import Placeholder from "components/Placeholder"
import HeroImage from "components/public/HeroImage"

import clsx from "clsx"
import { hasTokenGates } from "components/Memberships/SharedFunctions"
import PostHeader from "components/public/BlogTheme/PostPage/Header/PostHeader"
import PostSkeletonBody from "components/public/BlogTheme/PostPage/Body/PostSkeletonBody"
import TableOfContents from "components/TableOfContents"
import { useState } from "react"
import { useJumpLinks } from "hooks/useJumpLinks"

interface Props {
  currentNote: GetNoteResp
  blog: Blog
  loading: boolean
  hideComments: boolean
  hasAccess: boolean
  gatingRuleGroups: EnrichedGatingRuleGroup[]
}

export default function PostSkeleton(props: Props) {
  const [editorLoaded, setEditorLoaded] = useState(false)

  // Add jump links
  useJumpLinks(editorLoaded)

  // If we're still figuring out whether the user has access or not, return a simple placeholder.
  if (props.hasAccess == undefined) {
    return <Placeholder />
  }

  // Show Table of contents as long as it is not disabled, the editor is loaded,
  // and the user has access to the post
  const showToC =
    props.blog.enable_table_of_contents && editorLoaded && props.hasAccess

  const hasGatingRules =
    props.gatingRuleGroups && props.gatingRuleGroups.length > 0

  console.log("Does prop have access", props.hasAccess)

  return (
    <div className="w-full">
      <div className="break-words flex justify-between flex-col">
        {props.currentNote.cover_img &&
          (!hasGatingRules || props.hasAccess) && (
            <div className="mx-auto">
              <HeroImage note={props.currentNote} />
            </div>
          )}
        <div className="pt-2 sm:pt-4 lg:px-8 mx-auto min-w-full max-w-full">
          <header className="relative z-40">
            <div
              className={clsx(
                !hasGatingRules || props.hasAccess ? "" : "blur-md",
                "max-w-4xl px-4 mx-auto sm:px-6 lg:px-8"
              )}
            >
              <PostHeader {...props} />
            </div>
          </header>

          <main>
            <div className="flex max-w-[100rem] mx-auto justify-between">
              {showToC && <div className="toc-filler" />}
              <div
                className={clsx(
                  "mx-auto sm:px-6 lg:px-8 xl:flex-shrink-0 w-full",
                  hasGatingRules &&
                    !props.hasAccess &&
                    !hasTokenGates(props.gatingRuleGroups)
                    ? "max-w-5xl" // For the membership cards, display a modal width just wide enough to fit 3 cards well.
                    : hasGatingRules && !props.hasAccess
                    ? "max-w-6xl" // For the old token gate UI, display a wider modal.
                    : "max-w-4xl" // When a post is loaded, display the standard width.
                )}
              >
                <PostSkeletonBody
                  loading={props.loading}
                  hasAccess={props.hasAccess}
                  gatingRuleGroups={props.gatingRuleGroups}
                  hasGatingRules={hasGatingRules}
                  currentNote={props.currentNote}
                  hideComments={props.hideComments}
                  setEditorLoaded={setEditorLoaded}
                />
              </div>
              {showToC && <TableOfContents />}
            </div>
          </main>
        </div>
      </div>
    </div>
  )
}
