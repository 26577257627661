import { useEffect, useState } from "react"
import { useAnalytics } from "hooks/useAnalytics"
import { createNewUser } from "../features/userSlice"
import { useSelector } from "react-redux"
import { useRouter } from "next/router"
import {
  selectError,
  selectSuccess,
  setError,
  setSuccess,
} from "../features/errorSlice"
import Banner from "components/Banner"

import useUser from "hooks/useUser"

import Button from "components/Button"
import Link from "next/link"
import ConnectWalletBtn from "./ConnectWalletButton"
import { useAppDispatch } from "store"
import UnstoppableLogin from "./UnstoppableLogin"

interface Props {
  header?: React.ReactNode

  hideCryptoWallet?: boolean

  // URL to redirect to after successful registration.
  // This is used in the registration modal, after a guest tries to leave a comment.
  redirectAfterRegister?: string

  onRegisterSuccess?: () => void
}

export default function Register(props: Props) {
  const { track } = useAnalytics()

  const dispatch = useAppDispatch()
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const router = useRouter()
  const error = useSelector(selectError)
  const success = useSelector(selectSuccess)
  const user = useUser()
  const [buttonLoading, setButtonLoading] = useState(false)

  // After the user has successfully registered, redirect them appropriately.
  useEffect(() => {
    if (!user?.id) return

    if (props.onRegisterSuccess) {
      props.onRegisterSuccess()
      return

      // If this is the users first registration, redirect them to setup.
    }

    // Already registered user accessing this - redirect them!
  }, [router, user.id, props.onRegisterSuccess])

  const validateAndCreateUser = async (ev: any) => {
    dispatch(setError({ msg: "" }))
    dispatch(setSuccess(""))
    track("register button click", {
      email,
    })

    setButtonLoading(true)
    ev.preventDefault()

    if (!email || !password) {
      return
    }

    // Creating the user will cause the redirect in useAuth to trigger
    // upon successful registration
    dispatch(createNewUser({ email, password }))
    console.log("Done dispatching, neato!")
    //setButtonLoading(false)
  }

  useEffect(() => {
    return () => {
      dispatch(setError({ msg: "" }))
      setButtonLoading(false)
    }
  }, [])

  useEffect(() => {
    console.log("Trigger user ID stuff...")
    if (error.msg) {
      console.log("SETTING BUTTON LODAING TO FALSE")
      setButtonLoading(false)
    }
  }, [error.msg])

  useEffect(() => {
    console.log(router.query)
    if (router.query && router.query.email) {
      setEmail(router.query.email as string)
    }
  }, [router])

  return (
    <div>
      {props.header}
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="px-4 py-8 bg-white shadow sm:rounded-lg sm:px-10">
          <form className="space-y-6" onSubmit={validateAndCreateUser}>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email address
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  defaultValue={email}
                  onChange={(e) => {
                    track("register email input changed", {
                      email: e.target.value,
                    })
                    setEmail(e.target.value)
                  }}
                  required
                  className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 appearance-none rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                Password
              </label>
              <div className="mt-1">
                <input
                  id="password"
                  name="password"
                  type="password"
                  onChange={(e) => {
                    track("register password input changed")
                    setPassword(e.target.value)
                  }}
                  autoComplete="current-password"
                  required
                  className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 appearance-none rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <Button
                text="Register"
                loading={buttonLoading}
                className="flex w-full ml-0"
              />
              <div className="text-gray-500 text-xs mt-4">
                Already have an account?{" "}
                <span className="text-blue-400">
                  <Link href={`/login?redirect=${router.asPath}`}>Login.</Link>.
                </span>
              </div>
            </div>

            {!props.hideCryptoWallet && (
              <div className="mt-6">
                <div className="relative">
                  <div className="absolute inset-0 flex items-center">
                    <div className="w-full border-t border-gray-300" />
                  </div>
                  <div className="relative flex justify-center text-sm">
                    <span className="px-2 bg-white text-gray-500">
                      Or connect your crypto wallet
                    </span>
                  </div>
                </div>

                <div className="mt-6 grid">
                  <div>
                    <ConnectWalletBtn hideError />
                  </div>
                </div>

                <UnstoppableLogin />
              </div>
            )}

            {error.msg && <Banner type="error" text={error.msg} />}
            {success && <Banner type="success" text={success} />}
          </form>
        </div>
      </div>
    </div>
  )
}
