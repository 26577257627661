import { EnrichedGatingRuleGroup } from "@/types/gatingRules"
import {
  LockClosedIcon as LockClosedIconSolid,
  LockOpenIcon as LockOpenIconSolid,
} from "@heroicons/react/24/solid"
import { GetNoteResp } from "@types"

import GatingRulesShortSummary from "components/ReaderGates/GatingRulesShortSummary"

export default function GatingRuleInfo(props: {
  hasAccess: boolean
  currentNote: GetNoteResp
  gatingRuleGroups: EnrichedGatingRuleGroup[]
}) {
  return (
    <div className="my-auto group">
      <div className="">
        {props.hasAccess && (
          <span className="rounded-lg inline-flex px-3 py-1">
            <LockOpenIconSolid
              className="w-4 h-4 text-green-500"
              aria-hidden="true"
            />
          </span>
        )}
        {!props.hasAccess && (
          <span className="rounded-lg inline-flex px-3 py-1">
            <LockClosedIconSolid
              className="w-4 h-4 text-red-500"
              aria-hidden="true"
            />
          </span>
        )}
      </div>
      {/* Show a short summary of why the user has access if they do have access and there are gates setup. */}
      <div
        className="invisible group-hover:visible absolute left-0 sm:left-20 md:left-auto z-10 
          transition-all duration-500 opacity-0 group-hover:opacity-100"
      >
        {props.hasAccess && (
          <GatingRulesShortSummary
            note={props.currentNote}
            gatingRuleGroups={props.gatingRuleGroups}
          />
        )}
      </div>
    </div>
  )
}
