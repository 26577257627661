import { useSelector } from "react-redux"
import { selectCurrentNote } from "features/noteSlice"

import NextImage from "next/image"
import Image from "components/Collectibles/Popover/CollectibleImage"
import CollectPostBtn from "components/Collectibles/CollectPostPopoverBtn"
import SubscribeButton from "components/SubscribeButton"
import {
  selectFreeUpsellMembership,
  selectUpsellMemberships,
} from "features/membershipSlice"
import { selectBlog } from "features/blogSlice"
import clsx from "clsx"
import useBlogHasCollectibles from "hooks/collectibles/useBlogHasCollectibles"
import ArweaveBanner from "components/crypto/ArweaveBanner"

function CollectibleImage() {
  const note = useSelector(selectCurrentNote)
  const collectibleName = note?.title || note?.subtitle || ""

  return (
    <div className="w-24 sm:w-32">
      <Image
        text={collectibleName}
        collectibleType="POST"
        placeholderSize={128}
      />
    </div>
  )
}

function SubscribeImage({ image }: { image: string }) {
  const blog = useSelector(selectBlog)

  if (!image) return null

  return (
    <div className="w-24 sm:w-32">
      {/*className="border border-custom-200 shadow-md rounded-lg "*/}
      <NextImage
        src={image}
        className="rounded-lg"
        alt={blog.name + " logo"}
        width={160}
        height={160}
      />
    </div>
  )
}

function CollectibleBox({
  containerClassName,
}: {
  containerClassName?: string
}) {
  return (
    <div className={containerClassName}>
      <div className="flex flex-row justify-between items-center space-x-6 h-full">
        <div>
          <CollectibleImage />
        </div>

        <div className="flex flex-col justify-center items-center  text-center h-full w-full">
          <div className="text text-custom-400 text-sm">
            Collect this post to permanently own it.
          </div>

          <div className="py-4 w-full">
            <CollectPostBtn hideAvatar full />
          </div>
        </div>
      </div>
    </div>
  )
}

function useMembershipImage() {
  const freeMembershipImg = useSelector(selectFreeUpsellMembership)?.image?.img
    ?.src
  const memberships = useSelector(selectUpsellMemberships)

  const anyMembershipImg = memberships?.find((m) => !!m.image?.img.src)?.image
    ?.img?.src

  const blog = useSelector(selectBlog)
  const note = useSelector(selectCurrentNote)

  const authorImage = note?.authorDetails?.find(
    (a) => !!a.avatar_url
  )?.avatar_url

  return (
    freeMembershipImg ||
    anyMembershipImg ||
    blog?.logo_url ||
    authorImage ||
    null
  )
}

function SubscribeBox({ containerClassName }: { containerClassName?: string }) {
  const blog = useSelector(selectBlog)
  const image = useMembershipImage()
  const hasImage = !!image

  return (
    <div className={clsx(containerClassName)}>
      <div
        className={clsx(
          hasImage ? "justify-between" : "justify-center",
          "flex flex-row items-center space-x-6 h-full"
        )}
      >
        {hasImage && (
          <div className="">
            <SubscribeImage image={image} />
          </div>
        )}

        <div className="flex flex-col justify-center items-center  text-center w-full">
          <div className="text text-custom-400 text-sm">
            Subscribe to {blog.name} and never miss a post.
          </div>

          <div className="py-4 w-full">
            <SubscribeButton className="w-full" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default function Boxes() {
  const postHasCollectibles = useBlogHasCollectibles()
  const baseBoxStyle = "justify-center w-full border-custom-100 p-6"

  if (postHasCollectibles) {
    const leftBoxStyle = `${baseBoxStyle} sm:mt-0 mt-8 sm:w-1/2 sm:rounded-l-lg rounded-lg sm:rounded-r-none    border`
    const rightBoxStyle = `${baseBoxStyle} mt-3 sm:mt-0 sm:w-1/2 rounded-lg sm:rounded-r-lg sm:rounded-l-none border sm:border-l-0  `
    return (
      <div>
        <div className="flex flex-col sm:flex-row mt-8">
          <CollectibleBox containerClassName={leftBoxStyle} />
          <SubscribeBox containerClassName={rightBoxStyle} />
        </div>
        <ArweaveBanner />
      </div>
    )
  }

  const subscribeBoxStyle = `${baseBoxStyle} border mt-8 sm:mt-0 rounded-lg`

  return (
    <div className="flex flex-col mt-8 w-full sm:w-2/3 mx-auto">
      <SubscribeBox containerClassName={subscribeBoxStyle} />
      <ArweaveBanner />
    </div>
  )
}
