import { Fragment, useState } from "react"
import { useSelector } from "react-redux"
import {
  selectComments,
  getCommentsForNote,
  selectFcComments,
  getFcCommentsForNote,
} from "features/commentSlice"
import ReactTimeAgo from "components/TimeAgo"
import { useEffect } from "react"
import AddComment from "components/AddComment"
import EditComment from "components/EditComment"
import UserAvatar from "./UserAvatar"
import useBlogUrl from "hooks/useBlogUrl"
import { CommentAndRepliesWithUser, CommentWithUser, Note } from "@types"
import useAuthorName from "hooks/useAuthorName"
import Linkify from "react-linkify"
import { selectUser } from "../features/userSlice"
import { selectBlog } from "features/blogSlice"
import { deleteComment } from "features/commentSlice"
import { useAppDispatch } from "store"
import Banner from "components/Banner"
import FarcasterCommentEntry from "./public/FarcasterCommentEntry"

interface CommentFeedProps {
  currentNote: Note
}

export default function CommentFeed(props: CommentFeedProps) {
  const comments = useSelector(selectComments)
  const fcComments = useSelector(selectFcComments)
  const [showReplyBox, setShowReplyBox] = useState("")
  const [showEditBox, setShowEditBox] = useState("")
  const dispatch = useAppDispatch()

  const blog = useSelector(selectBlog)
  const deleteDispatch = useAppDispatch()
  const [showErrorBannerForCommentId, setShowErrorBannerForCommentId] =
    useState("")

  const deleteCommentHandler = async (commentId: string) => {
    try {
      await deleteDispatch(deleteComment(commentId))
    } catch (err) {
      setShowErrorBannerForCommentId(commentId)
    }
  }

  useEffect(() => {
    if (!props?.currentNote?.id) return

    console.log("Getting comments for note...", props.currentNote)

    if (blog.disable_comments === true) return

    if (blog.disable_comments !== "on-platform") {
      dispatch(getCommentsForNote(props.currentNote.id))
    }

    dispatch(getFcCommentsForNote(props.currentNote.id))
  }, [dispatch, props.currentNote.id])

  const CommentElem = ({
    comment,
    replies,
    idx,
  }: {
    comment: CommentWithUser
    replies?: Array<CommentAndRepliesWithUser>
    idx: number
  }) => {
    // If the comment doesn't belong to a blog because it's been soft-deleted, set host and URL to empty strings.
    const { host, url } = useBlogUrl({ blog: comment.blog })
    // If the comment doesn't belong to a user because it's been soft-deleted, set authorName to an empty string.
    const authorName = useAuthorName({ user: comment.user, blog: comment.blog })
    const loggedInUser = useSelector(selectUser)
    const blog = useSelector(selectBlog)

    return (
      <div key={comment.id}>
        <div className="relative pb-8">
          {replies && replies?.length > 0 && (
            <div
              aria-hidden="true"
              className="absolute top-0 left-5 border-l border-gray-150 h-full"
            ></div>
          )}
          <div className="relative flex items-start space-x-3">
            <>
              <div className="relative">
                <UserAvatar
                  className="inline-block h-10 w-10 rounded-full"
                  url={comment?.user?.avatar_url}
                />
              </div>
              <div className="min-w-0 flex-1">
                <div>
                  <div className="text-sm">
                    <span className="font-medium text-custom-900">
                      <a
                        target="_blank"
                        className="dont-break-out"
                        rel="noreferrer"
                        href={host + url}
                      >
                        {authorName.name}
                      </a>
                    </span>
                  </div>
                  <p className="mt-0.5 text-xs text-custom-400">
                    Commented{" "}
                    <ReactTimeAgo date={comment.createdAt} locale="en-US" />
                  </p>
                </div>
                {showEditBox !== comment.id && (
                  <div className="mt-2 text-sm text-custom-700">
                    <p className="prose prose-sm whitespace-pre-line">
                      <Linkify
                        componentDecorator={(
                          decoratedHref,
                          decoratedText,
                          key
                        ) => (
                          <a target="blank" href={decoratedHref} key={key}>
                            {decoratedText}
                          </a>
                        )}
                      >
                        {comment.text}
                      </Linkify>
                    </p>
                  </div>
                )}

                {showEditBox === comment.id && (
                  <div className="">
                    <EditComment
                      setShowCommentBox={(show: boolean) =>
                        show
                          ? setShowEditBox(comment.id || "")
                          : setShowEditBox("")
                      }
                      editExistingComment={comment}
                    />
                  </div>
                )}

                <div className="flex justify-between">
                  <button
                    onClick={() => setShowReplyBox(comment.id || "")}
                    className="mt-0.5 text-sm font-semibold text-custom-400"
                  >
                    Reply
                  </button>
                  <div className="flex justify-end">
                    {comment.userId === loggedInUser.id && ( // If you own the comment.
                      <button
                        onClick={() => setShowEditBox(comment.id || "")}
                        className="mt-0.5 text-sm font-semibold text-custom-400 mr-4"
                      >
                        Edit
                      </button>
                    )}
                    {(comment.userId === loggedInUser.id || // If you own the comment.
                      loggedInUser.id === blog.user?.id) && ( // Or if you own the blog the comment has been made on.
                      <button
                        onClick={() => deleteCommentHandler(comment.id || "")}
                        className="mt-0.5 text-sm font-semibold text-custom-400"
                      >
                        Delete
                      </button>
                    )}
                  </div>
                </div>
                {showErrorBannerForCommentId &&
                  showErrorBannerForCommentId == comment.id && (
                    <div className="mt-2">
                      <Banner
                        className="mb-2"
                        type="error"
                        text={"Error deleting comment."}
                      />
                    </div>
                  )}
              </div>
            </>
          </div>

          {showReplyBox === comment.id && (
            <div className="mt-4 ml-9">
              <AddComment
                setShowCommentBox={(show: boolean) =>
                  show ? setShowReplyBox(comment.id || "") : setShowReplyBox("")
                }
                replyToCommentId={comment.id}
              />
            </div>
          )}

          {replies && Array.isArray(replies) && (
            <div className="ml-9 mt-4">
              {replies?.map((entry, idx) => (
                <CommentElem
                  key={entry.comment.id}
                  comment={entry.comment}
                  replies={entry.replies}
                  idx={idx}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    )
  }

  const sortedComments = [...(comments || []), ...(fcComments || [])].sort(
    (c1, c2) => {
      const firstComment =
        "comment" in c1 ? c1.comment.createdAt : c1.parent.timestamp
      const secondComment =
        "comment" in c2 ? c2.comment.createdAt : c2.parent.timestamp

      return secondComment - firstComment
    }
  )

  console.log("Sorted comments", { sortedComments, fcComments, comments })

  return (
    <div className="flow-root">
      <ul role="list" className="">
        {!sortedComments && (
          <li className="text-sm text-custom-400 text-center py-4">
            Loading comments...
          </li>
        )}

        {sortedComments.map((comment, idx) =>
          "comment" in comment ? (
            <CommentElem
              key={comment?.comment?.id}
              comment={comment.comment}
              replies={comment.replies}
              idx={idx}
            />
          ) : (
            <FarcasterCommentEntry
              key={comment?.parent?.hash}
              thread={comment}
              showNestedComments={false}
            />
          )
        )}
      </ul>
    </div>
  )
}
